import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

import '../css/tools.css';

export default function StationSearch() {

    const navigate = useNavigate();

    const [query, setQuery] = useState('');
    const [stations, setStations] = useState([]);

    useEffect(() => {
        if(query) {
            axios.get('https://api.trains.sh/stations/search?query=' + query)
                .then(({data}) => setStations(data));
        } else {
            setStations([]);
        }
    }, [query]);

    function getTimetable(eva) {
        navigate('/timetable/' + eva);
    }

    return (
        <div className="relative">
            <input className="text-slate-800 p-1 outline-0" placeholder="Station input (e.g. Knuffingen Hbf)" name="station" onChange={(e) => setQuery(e.target.value)}/>
            {stations.length > 0 && (
                <div className="absolute block overflow-auto bg-slate-800 border-2 border-slate-600 z-10 left-0 right-0 top-[120%] max-h-[10rem] px-2 no-scrollbar">
                    {stations.map((s, i) => (
                        <div key={i} className="hover:bg-slate-900 cursor-pointer" onClick={() => getTimetable(s.eva)}>{s.name + (s.ril100 ? ' [' + s.ril100 + ']' : '')}</div>
                    ))}
                </div>
            )}
        </div>
    )
}