import {Link} from "react-router-dom";

export default function Home() {
    return (
        <div>
            <a href="/journeys">
                <p className="font-semibold text-3xl">trains.sh/journeys</p>
            </a>
            <Link to="/stations">
                <p className="font-semibold text-3xl">trains.sh/stations</p>
            </Link>
            <Link to="/timetable">
                <p className="font-semibold text-3xl">trains.sh/timetable</p>
            </Link>
        </div>
    )
}