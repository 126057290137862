import {useNavigate} from "react-router-dom";

export default function Timetable() {

    const navigate = useNavigate();

    function getTimetable(e) {
        e.preventDefault();

        const eva = e.target.elements.eva.value;

        navigate('/timetable/' + eva);
    }

    return (
        <div>
            <form className="flex flex-col gap-2" onSubmit={getTimetable}>
                <input className="text-slate-800 p-1" placeholder="EVA Identifier (e.g. 8000xxx)" name="eva" inputMode="numeric" required/>
                <button className="bg-green-700 hover:bg-green-600 p-1">Get timetable</button>
            </form>
            <p>form proudly joinked from yan</p>
        </div>
    )
}