import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import moment from "moment";

import '../css/tools.css';
import {useParams} from "react-router-dom";

export default function TimetableView() {

    const {eva} = useParams();

    const [timetable, setTimetable] = useState([]);

    const fetchTimetable = useCallback(() => {
        const time = moment().format('yyyy-MM-DDTHH:mm');

        axios.get('https://api.trains.sh/timetable' +
            '?eva=' + eva +
            '&time=' + time +
            '&duration=PT2H'
        ).then(({data}) => data.map((entry) => {
            if (entry.departure) {
                const plannedTime = moment(entry.departure.plannedTime);
                const estimatedTime = entry.departure.estimatedTime ? moment(entry.departure.estimatedTime) : null;

                entry.departure.plannedTime = plannedTime;
                entry.departure.estimatedTime = estimatedTime;
            }

            if (entry.arrival) {
                const plannedTime = moment(entry.arrival.plannedTime);
                const estimatedTime = entry.arrival.estimatedTime ? moment(entry.arrival.estimatedTime) : null;

                entry.arrival.plannedTime = plannedTime;
                entry.arrival.estimatedTime = estimatedTime;
            }

            return entry;
        })).then(setTimetable);
    }, [eva]);

    useEffect(() => {
        fetchTimetable();

        const intervalId = setInterval(() => {
            fetchTimetable();
        }, 30 * 1000);

        return () => clearInterval(intervalId);
    }, [fetchTimetable]);

    return (
        <div className="flex flex-col flex-grow gap-4">
            <p className="font-thin text-5xl text-amber-300">> timetable --eva {eva}</p>
            <div className="bg-slate-800 h-0 flex-grow overflow-y-scroll gap-1 no-scrollbar">
                {timetable.filter((e) => !!e.departure).sort((a, b) => a.departure.plannedTime.diff(b.departure.plannedTime)).map((entry, i) => {

                    const trainName = entry.train.category + ' ' + (entry.train.line || entry.train.trainNumber);
                    const trainNumber = entry.train.category + ' ' + (entry.train.trainNumber);

                    const destination = entry.departure ? entry.departure.nextStations[entry.departure.nextStations.length - 1] : 'Nicht einsteigen';

                    const departureTimePlanned = moment(entry.departure.plannedTime);
                    const departureTimeEstimated = entry.departure.estimatedTime ? moment(entry.departure.estimatedTime) : null;

                    const delay = departureTimeEstimated ? departureTimeEstimated.diff(departureTimePlanned, 'minutes', true) : null;

                    const departureTimeColorClass = delay != null ? (delay === 0 ? 'text-green-400' : (delay <= 3 ? 'text-amber-400' : 'text-red-400')) : 'text-white';
                    const departureTime = departureTimeEstimated || departureTimePlanned;

                    const departurePlatform = entry.departure.estimatedPlatform || entry.departure.plannedPlatform;

                    const platformChange = !!entry.departure.platform && entry.departure.platform !== entry.departure.plannedPlatform;

                    const viaRegex = /(HB$|Hbf|Centraal|Flughafen)/;
                    const viaStations = entry.departure.nextStations.filter((e, i) => {
                        if(i + 1 === entry.departure.nextStations.length) {
                            return  false;
                        }

                        return viaRegex.test(e);
                    });

                    return (
                        <div key={i} className="p-2 border-b grid grid-cols-[auto_1fr] gap-2">
                            <div>
                            <p>
                                <span className="font-semibold">
                                    {trainName}
                                </span>
                                &nbsp;
                                <span>
                                    {destination}
                                    {viaStations.length > 0 && (
                                        <>
                                            &nbsp;
                                            <span className="text-gray-400">via {viaStations.join(' - ')}</span>
                                        </>
                                    )}
                                </span>
                            </p>
                                {!!entry.train.line && <p className="text-gray-400">
                                {trainNumber}
                            </p>}
                            </div>

                            {/*<div>{departureTimePlanned.format('HH:mm')}</div>*/}
                            <div>
                                <div className={"text-right " + departureTimeColorClass}>{departureTime.format('HH:mm')}{delay ? <>&nbsp;(+{delay})</> : ''}</div>
                                <div className={"text-right font-bold " + (platformChange ? 'text-red-400' : 'text-gray-300')}>{departurePlatform}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}