import {createBrowserRouter, RouterProvider} from "react-router-dom";
import TimetableView from "./views/TimetableView";
import Wrapper from "./components/Wrapper";
import Home from "./views/Home";
import Timetable from "./views/Timetable";
import StationSearch from "./views/StationSearch";

export default function App() {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home/>,
        },
        {
            path: "/stations",
            element: <StationSearch/>,
        },
        {
            path: "/timetable",
            element: <Timetable/>,
        },
        {
            path: "/timetable/:eva",
            element: <TimetableView/>,
        }
    ]);

    return (
        <Wrapper>
            <RouterProvider router={router} />
        </Wrapper>
    );
}
